import React from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
const { Paragraph, Text, Title } = Typography;

export const features = {
  sentence: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Paragraph>Bright, open floor plans.</Paragraph>
      <Paragraph>
        <Text mark strong>
          Full-size washer and dryer
        </Text>{' '}
        in every unit.
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Upgraded stainless steel appliances
        </Text>
        .
      </Paragraph>
      <Paragraph>Bright new laminate wood flooring.</Paragraph>
      <Paragraph>
        Lots of{' '}
        <Text mark strong>
          storage and closet space.
        </Text>
      </Paragraph>
      <Paragraph>
        Fast acting individual mini split{' '}
        <Text mark strong>
          heating and air conditions.
        </Text>
        .
      </Paragraph>
      <Paragraph>
        Separate water system and{' '}
        <Text mark strong>
          individual water heaters
        </Text>{' '}
        in each unit.
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Dual pane windows
        </Text>{' '}
        with efficient low energy glazing.
      </Paragraph>
      <Paragraph>
        Security with{' '}
        <Text mark strong>
          controlled access entry
        </Text>{' '}
        with RFID and intercom.
      </Paragraph>
      <Paragraph>
        Cameras at all entry and exits to building for added{' '}
        <Text mark strong>
          security
        </Text>
        .
      </Paragraph>
      <Paragraph>
        Secure, easy-access{' '}
        <Text mark strong>
          parking
        </Text>{' '}
        located beneath the building.
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Great location
        </Text>{' '}
        close to restaurants, shops & more!
      </Paragraph>
    </div>
  ),
  images: [
    {
      src: 'assets/pics/kitchen.jpg',
      caption: '',
      thumb: 'assets/pics/kitchen.jpg',
    },
    {
      src: 'assets/pics/laundry.jpg',
      caption: '',
      thumb: 'assets/pics/laundry.jpg',
    },
    {
      src: 'assets/pics/bedroom.jpg',
      caption: '',
      thumb: 'assets/pics/bedroom.jpg',
    },
    {
      src: 'assets/pics/bathroom.jpg',
      caption: '',
      thumb: 'assets/pics/bathroom.jpg',
    },
  ],
};
